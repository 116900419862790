import React, { useState, useRef, useEffect } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { animated, useTrail } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import headerJson from './assets/data/header.json'
import Home from '@interco/icons/build-v4/orangeds/MD/home'

import Layout from 'src/layouts/BaseLayout'
import Icon from 'src/components/UI/MarkdownIcon'
import HeaderCarousel from 'src/components/UI/Carousels/HeaderCarousel'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import FAQ from 'src/components/Faq'
import FollowOurNewsJSON from './assets/data/FollowOurNews.json'
import CreateYourCommunityJSON from './assets/data/CreateYourCommunity.json'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import isVisible from '../../../../utils/isVisible'
import useScroll from '../../../../hooks/window/useScroll'

import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'

import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

import { Wrapper, ContentHeader, EnjoyTheAdvantagesSection, EnjoyTheAdvantagesBackground, BoxCard, CreateYourCommunitySection } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

type FollowOurNewsProps = {
  icon: string;
  title: string;
  description: string;
  buttonText: string;
  url: string;
}

type CreateYourCommunityProps = {
  image: string;
  title?: string;
  text?: string;
  question?: string;
  step?: string;
}

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaComunidadeDeInvestimentos } = qrcodeInvestimentsData

const ComunidadeDeInvestimentos = () => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const [ imageIndex, setImageIndex ] = useState(0)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const domReady = useDomReady()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

// Refs
const contentHeaderRef = useRef<HTMLElement>(null)
const moreInvestorsRef = useRef<HTMLElement>(null)
const enjoyTheAdvantagesRef = useRef<HTMLElement>(null)
const createYourCommunityRef = useRef<HTMLElement>(null)

// Animations
const [ animatedContentHeader, setAnimatedAnimatedContentHeader ] = useTrail(2, () => (fadeFrom))
const [ animatedMoreInvestors, setAnimatedAnimatedMoreInvestors ] = useTrail(2, () => (fadeFrom))
const [ enjoyTheAdvantages, setAnimatedEnjoyTheAdvantages ] = useTrail(2, () => (fadeFrom))
const [ createYourCommunity, setAnimatedCreateYourCommunity ] = useTrail(2, () => (fadeFrom))

 useEffect(() => {
  if (isVisible(contentHeaderRef.current, -0.3)) {
    setAnimatedAnimatedContentHeader((fadeTo))
  }
  if (isVisible(moreInvestorsRef.current, -0.3)) {
    setAnimatedAnimatedMoreInvestors((fadeTo))
  }
  if (isVisible(enjoyTheAdvantagesRef.current, -0.3)) {
    setAnimatedEnjoyTheAdvantages((fadeTo))
  }
  if (isVisible(createYourCommunityRef.current, -0.3)) {
    setAnimatedCreateYourCommunity((fadeTo))
  }
}, [ scroll ])

  const imageNames = [
    'criarComunidade',
    'acessarInvestimentos',
    'escolhaUmNome',
    'convideAmigos',
    'atividades',
  ]

  const images = useStaticQuery(graphql`
    query {
      criarComunidade: imageSharp(fluid: {originalName: { regex: "/criar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      acessarInvestimentos: imageSharp(fluid: {originalName: { regex: "/para-comecar/" }}) {
        fluid(maxWidth: 254, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      escolhaUmNome: imageSharp(fluid: {originalName: { regex: "/escolher-nome/" }}) {
        fluid(maxWidth: 254, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      convideAmigos: imageSharp(fluid: {originalName: { regex: "/convide-amigos/" }}) {
        fluid(maxWidth: 254, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      atividades: imageSharp(fluid: {originalName: { regex: "/atividades/" }}) {
        fluid(maxWidth: 254, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  const openAccount = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink} qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode} closeModal={() => setIsModalOpen(false)} qrBaixeApp={qrCodeAberturaDeContaComunidadeDeInvestimentos.qrcode} asFilledLink={qrCodeAberturaDeContaComunidadeDeInvestimentos.deeplink} />
    </Modal>
  )

function changeImage (nextSlide: number) {
  setImageIndex(nextSlide)
}

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccount}
        <ContentHeader ref={contentHeaderRef} className='pt-4 pb-5 pt-md-5 bg-white'>
          <div className='container'>
            <HeaderCarousel>
              <div className='row mx-0 align-items-center justify-content-center justify-content-lg-between'>
                <div className='col-12 col-md-6 col-lg-5 offset-lg-1 order-md-last px-0 mb-3 mb-md-0'>
                  <Img fluid={data.turbineSeuCashback.fluid} alt='Cliente Inter no notebook' />
                </div>
                <div className='col-12 px-0 mb-3 breadcrumb'>
                  <div className='bread'>
                    <Link
                      to='/'
                      className=' d-md-inline'
                    ><Home height={24} width={24} color='#6A6E81' />
                    </Link>
                    <OrangeIcon className='d-md-inline mr-1' icon='arrow-right' color='#161616' size='SM' />
                    <Link
                      to='/pra-voce/investimentos/'
                      className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline'
                    >
                      {headerJson.breadcrumb[0]}
                    </Link>
                    <OrangeIcon icon='arrow-right' color='#161616' size='SM' className='d-md-inline ml-1' />
                    <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
                  </div>
                </div>
                <div className='col-12 col-md-6 pl-0'>
                  <animated.h1 style={animatedContentHeader[0]} className='fs-32 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60 text-grayscale--500 fw-600 mb-0 mb-md-3'>
                    <span className='d-xl-block'>Ganhe Pontos Loop</span> <span className='d-xl-block'>com as comunidades</span> de investimentos
                  </animated.h1>
                  <animated.p style={animatedContentHeader[1]} className='fs-18 lh-22 text-grayscale--500 mb-lg-4 mt-3'>
                    Investidores que façam parte de comunidades conseguem turbinar os retornos dos seus títulos de renda fixa e garantem até 40% da receita de distribuição de fundos de investimento em Pontos Loop.
                  </animated.p>
                  <animated.div style={animatedContentHeader[1]}>
                    <a
                      href='https://intergo.app/4b620ff6'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: 'Ganhar Pontos Loop',
                          section_name: 'Ganhe Pontos Loop com as comunidades de investimentos',
                          redirect_url: 'https://intergo.app/4b620ff6',
                        })
                      }}
                    >
                      <button
                        title='Ganhar Pontos Loop'
                        className='d-block d-md-none fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-5'
                      >
                        Ganhar Pontos Loop
                      </button>
                    </a>
                    <button
                      onClick={() => {
                        setIsModalOpen(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: 'Ganhar Pontos Loop',
                          section_name: 'Ganhe Pontos Loop com as comunidades de investimentos',
                        })
                      }
                      }
                      title='Ganhar Pontos Loop'
                      className='d-none d-md-block fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-5'
                    >
                      Ganhar Pontos Loop
                    </button>
                  </animated.div>
                </div>
              </div>
              <div className='row mx-0 align-items-center justify-content-center justify-content-lg-between'>
                <div className='col-12 col-md-6 col-lg-5 offset-lg-1 pt-4 pt-md-0 order-md-last px-0 mb-3 mb-md-0'>
                  <Img fluid={data.junteSeusAmigos.fluid} alt='Cliente Inter ao celular' />
                </div>
                <div className='col-12 col-md-6 col-xl-5 pl-0'>
                  <h2 className='fs-32 fs-lg-40 fs-xl-48 lh-40 lh-lg-50 lh-xl-60 text-grayscale--500 mb-0 mb-md-3'>
                    <span className='d-md-block d-lg-inline'>Junte seus</span> amigos, invista e ganhe mais
                  </h2>
                  <p className='fs-18 lh-22 text-grayscale--500 mb-lg-4 mt-3'>
                    No Inter, ao investir com uma Comunidade, o patrimônio de todos os membros é considerado em conjunto para aumentar a rentabilidade dos seus investimentos.
                  </p>
                  <a
                    href='https://intergo.app/4b620ff6'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        element_action: 'click button',
                        element_name: 'Quero Investir',
                        section_name: 'Ganhe cashback com as comunidades de investimentos',
                        redirect_url: 'https://intergo.app/4b620ff6',
                      })
                    }}
                  >
                    <button
                      title='Quero Investir'
                      className='d-block d-md-none fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-0'
                    > Quero Investir
                    </button>
                  </a>

                  <button
                    title='Quero Investir'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        element_action: 'click button',
                        element_name: 'Quero Investir',
                        section_name: 'Ganhe cashback com as comunidades de investimentos',
                      })
                    }}
                    className='d-none d-md-block fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-0'
                  > Quero Investir
                  </button>
                </div>
              </div>
            </HeaderCarousel>
          </div>
        </ContentHeader>
        <section ref={moreInvestorsRef} className='bg-gray'>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6'>
                <animated.h3 style={animatedMoreInvestors[0]} className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500'>
                  <span className='d-xl-block'>Quanto mais</span> <span className='d-xl-block'>investidores, maior</span> a rentabilidade
                </animated.h3>
              </div>
              <animated.div style={animatedMoreInvestors[1]} className='col-12 col-md-6'>
                <p className='fs-18 lh-22 text-grayscale--500'>Você pode criar sua Comunidade de Investimentos ou participar de uma comunidade já existente. Cada comunidade tem até 20 pessoas.</p>
                <p className='fs-18 lh-22 text-grayscale--500'>Aplicando a partir de R$250 mil em conjunto, você já garante uma rentabilidade superior a individual.</p>
                <p className='fs-18 lh-22 text-grayscale--500'>O volume de seus investimentos não fica visível para a comunidade e você compartilha suas movimentações apenas  se quiser.</p>
              </animated.div>
            </div>
          </div>
        </section>
        <EnjoyTheAdvantagesSection ref={enjoyTheAdvantagesRef} className='pb-5 pb-md-0 d-md-flex align-items-md-center'>
          <EnjoyTheAdvantagesBackground
            fluid={[
              data.enjoyTheAdvantagesSM.fluid,
              {
                ...data.enjoyTheAdvantagesMD.fluid,
                media: '(min-width: 768px)',
              },
              {
                ...data.enjoyTheAdvantagesLG.fluid,
                media: '(min-width: 992px)',
              },
              {
                ...data.enjoyTheAdvantagesXL.fluid,
                media: '(min-width: 1200px)',
              },
            ]}
          />
          <div className='container pt-0'>
            <div className='row'>
              <div className='col-12 col-md-6 offset-md-6 col-lg-5 offset-lg-7'>
                <animated.h3 style={enjoyTheAdvantages[0]} className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500'>
                  Aproveite as vantagens de investir em comunidade
                </animated.h3>
                <animated.p style={enjoyTheAdvantages[1]} className='fs-18 lh-22 text-grayscale--500'>
                  Aumente a sua rentabilidade, acompanhe as aplicações dos outros investidores, curta, comente e compartilhe e ganhe cashback turbinado para você ir cada vez mais longe.
                </animated.p>
                <animated.div style={enjoyTheAdvantages[1]}>
                  <a
                    href='https://intergo.app/4b620ff6'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click button',
                        element_name: 'Quero Investir',
                        section_name: 'Aproveite as vantagens de investir em comunidade',
                      })
                    }}
                  >
                    <button
                      title='Quero Investir'
                      className='d-block d-md-none fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-0 btn--lg'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_02',
                          element_action: 'click button',
                          element_name: 'Quero Investir',
                          section_name: 'Aproveite as vantagens de investir em comunidade',
                        })
                      }}
                    > Quero Investir
                    </button>
                  </a>

                  <button
                    title='Quero Investir'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_02',
                        element_action: 'click button',
                        element_name: 'Quero Investir',
                        section_name: 'Aproveite as vantagens de investir em comunidade',
                      })
                    }}
                    className='d-none d-md-block fs-14 fw-600 btn btn-orange--extra text-none rounded-3 mb-2 mb-md-5 mb-lg-0 btn--lg'
                  >
                    Quero Investir
                  </button>
                </animated.div>
              </div>
            </div>
          </div>
        </EnjoyTheAdvantagesSection>
        <CreateYourCommunitySection ref={createYourCommunityRef} className='bg-gray d-flex align-items-md-center'>
          <div className='container pt-4 pb-5'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6 col-lg-6 col-xl-5'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 1 }}
                  lg={{ items: 1 }}
                  xl={{ items: 1 }}
                  beforeChange={(nextSlide: number) => changeImage(nextSlide)}
                >
                  {
                  CreateYourCommunityJSON.map((item: CreateYourCommunityProps) => (
                    <div key={item.title}>
                      <div className='content-text'>
                        <animated.p
                          style={createYourCommunity[0]}
                          className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600 title'
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <animated.p
                          style={createYourCommunity[1]}
                          className='fs-18 lh-22 text-grayscale--500'
                        >{item.text}
                        </animated.p>
                        <animated.p
                          style={createYourCommunity[0]}
                          className='fs-16 lh-20 text-grayscale--500 fw-600 question'
                        >{item.question}
                        </animated.p>
                        <animated.p
                          style={createYourCommunity[1]}
                          className='fs-20 fs-xl-32 lh-25 lh-xl-40 text-grayscale--500 fw-600 step'
                          dangerouslySetInnerHTML={{ __html: item.step }}
                        />
                      </div>
                      <div className='col-12 col-md-6 col-xl-3 offset-xl-1 d-md-none'>
                        <Img fluid={data[item.image].fluid} alt='Passo a passo de como criar comunidade de investimentos no Inter' />
                      </div>
                    </div>
                  ))
                }
                </DefaultCarousel>
              </div>
              <div className='col-12 col-md-6 col-lg-5 offset-lg-1 col-xl-4 offset-xl-3 d-none d-md-block'>
                <Img fluid={images[imageNames[imageIndex]].fluid} alt='Passo a passo de como criar comunidade de investimentos no Inter' />
              </div>
            </div>
          </div>
        </CreateYourCommunitySection>
        <section id='como-investir' className='bg-white pb-3'>
          <div className='container py-5'>
            <div className='row justify-content-center align-items-center justify-content-md-between'>
              <div className='col-12 col-lg-9 col-xl-5 mx-auto ml-xl-0 text-md-center text-xl-left mb-3 mb-md-4'>
                <h2 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-grayscale--500 pt-2'>
                  Acompanhe nossas novidades
                </h2>
                <p className='fs-18 lh-22 text-grayscale--500 mb-0'>
                  Fique por dentro de tudo que acontece no Inter e saiba de todas as informações de
                  <a
                    href='https://inter.co/pra-voce/investimentos/'
                    target='_blank' rel='noreferrer'
                    onClick={() => {
                      setIsModalOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        element_action: 'click button',
                        element_name: '',
                        section_name: 'Acompanhe nossas novidades',
                      })
                    }}
                  ><span className='text-orange--extra fw-600'> investimentos </span>
                  </a> em primeira mão.
                </p>
              </div>
              <div className='col-12 col-xl-6 px-0'>
                {
                  FollowOurNewsJSON.map((item: FollowOurNewsProps, index: number) => (
                    <BoxCard className='col-12 col-lg-8 col-xl-12 mx-auto pt-3 pb-2' key={index}>
                      <div className='d-flex flex-column pl-4 pr-3 pt-4 pb-2 py-md-4 bg-white border rounded-4 justify-content-end voce-por-dentro'>
                        <div className='row mx-0'>
                          <div className='col-12 col-md-5 col-xl-6 align-self-end'>
                            <div className='d-flex'>
                              <Img fixed={data[item.icon].fixed} alt={item.title} className='mb-2 mr-3' />
                              <span className='text-orange--extra fs-16 lh-20 mb-3 mb-md-4 fw-600'>{item.title}</span>
                            </div>
                            <h3 className='fs-16 lh-18 pb-1 fw-600 text-grayscale--500 mb-0'>
                              {item.description}
                            </h3>
                          </div>
                          <div className='col-12 col-md-7 col-xl-6 mt-2 align-self-end'>
                            <a
                              href={item.url} title='Veja nossos relatórios'
                              target='_blank'
                              rel='noopener noreferrer'
                              className='fs-14 lh-17 fw-400 text-orange--extra float-right'
                              onClick={() => {
                                sendDatalayerEvent({
                                  section: 'dobra_05',
                                  element_action: 'click button',
                                  element_name: item.title,
                                  section_name: 'Acompanhe nossas novidades',
                                  redirect_url: item.url,
                                })
                              }}
                            >
                              {item.buttonText}
                              <Icon width='27.12' height='24' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </BoxCard>
                  ))
                }
              </div>
            </div>
          </div>
        </section>
        <section className='pb-5'>
          <div className='container'>
            <div className='row'>
              <div className='mb-4'>
                <h3 className='text-grayscale--500 fw-600 fs-24 fs-lg-40 fs-xl-40 lh-24 lh-lg-50 lh-xl-48 mb-2 mb-lg-0'>Ficou com alguma dúvida?</h3>
                <p className='text-grayscale--500 fs-16 lh-18 fs-md-18 lh-md-22'>
                  Veja se podemos te ajudar com essas perguntas frequentes.
                </p>
              </div>
              <FAQ
                columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
                className='px-0'
                answerData={pageContext.structuredData.faq}
              />
            </div>
          </div>
          <DisclaimerInterInvest
            sendDatalayerEvent={sendDatalayerEvent}
            dataLayer={dataLayer}
            section='dobra_07'
          />
        </section>
      </Layout>
    </Wrapper>
  )
}

export default ComunidadeDeInvestimentos
