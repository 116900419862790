import { orange } from 'src/styles/colors';
import styled from 'styled-components';

type ProgressProps = {
  progress: number;
}

export const Container = styled.div`
  position: relative;

  .react-multi-carousel-track {
    padding: 30px 0 50px;
  }

  .react-multiple-carousel__arrow {
    bottom: 0;
    background-color: ${orange.extra};
    height: 32px;
    width: 32px;
    outline: none;
    z-index: 1;
    min-width: auto;
    min-height: auto;
    align-items: center;
    display: flex;

    &:disabled {
      cursor: default;
      opacity: 0.5;
      z-index: 0;
    }

    &--right {
      left: 190px;
      right: auto;
      
      svg {
        left: 6px;
        position: relative;
      }
    }

    &--left {
      left: 0;

      svg {
        left: 5px;
        position: relative;
      }
    }

    &::before {
      content: none;
    }
  }
`

export const ProgressBar = styled.div`
  text-align: left;
  background-color: rgba(231, 231, 231, 0.5);
  border-radius: 100px;
  height: 4px;
  margin: 0 10px 0 10px;
  width: 135px;
  position: absolute;
  bottom: 14px;
  left: 35px;

  > span {
    display: block;
    background-color: ${orange.base};
    border-radius: 100px;
    height: 4px;
    opacity: 1.0;
    transition: all 0.5s ease;
    width: ${(props: ProgressProps) => `${props.progress}%`};
  }
`

export const Counter = styled.span`
  position: absolute;
  left: 240px;
  bottom: 5px;
`
