import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/chevron-left'
import React from 'react'
import { ArrowProps } from 'react-multi-carousel'

function LeftArrow ({ onClick }: ArrowProps) {
  return (
    <button
      onClick={onClick} type='button'
      className='border-0 rounded-100 react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <ArrowLeft width={20} height={20} color='white' />
    </button>
  )
}

export default LeftArrow
